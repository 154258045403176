import { Button, Flex, Tag } from "antd";
import React, { useState } from "react";
import { SupportRequestMessage } from "../../../types/SupportRequestItem.type";
import { SupportStatusEnum } from "../../../types/SupportStatus.enum";

import './SupportRequestDetails.scss'
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useParams } from "react-router-dom";
import { useCreateResponseMutation, useGetAdminSupportRequestQuery } from "../../../store/supportApiSlice";
import { Loader } from "../../../Components/common/Loader";
import { SupportMessage } from "./SupportMessage";
import { SupportCategoryEnum } from "../../../types/SupportCategory.enum";
import { SupportPriorityEnum } from "../../../types/SupportPriority.enum";

export const SupportRequestDetails: React.FC = ()=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  let params = useParams()
  const { data, isLoading } = useGetAdminSupportRequestQuery(params)
  const [ send ] = useCreateResponseMutation()
  const [answer, setAnswer] = useState<string>('')

  const sendSupportAnswer =async ()=>{
    const payload = {
      text: answer,
      reply_to: data.messages[data.messages.length-1].id,
      request_id: data.id
    }
    await send(payload)
    setAnswer('')


  }

  if (isLoading) return <Loader />

  return <>
    <div className="supportRequestDetails">
      <div className="supportRequestDetailsSubject">
        <div className="supportRequestCardHeaderTags">
        
          {data.status===SupportStatusEnum.OPEN && <Tag style={{height: '22px'}} color="warning">Открыта</Tag>}
          {data.status===SupportStatusEnum.CLOSED && <Tag style={{height: '22px'}} color="success">Закрыта</Tag>}

          {data.category===SupportCategoryEnum.COMMON && <Tag  style={{height: '22px'}}>Общее</Tag>}
          {data.category===SupportCategoryEnum.FEEDBACK && <Tag  style={{height: '22px'}}>Отзывы</Tag>}
          {data.category===SupportCategoryEnum.OTHER && <Tag  style={{height: '22px'}}>Другие</Tag>}
          {data.category===SupportCategoryEnum.Suggestions && <Tag  style={{height: '22px'}}>Предложения</Tag>}
          {data.category===SupportCategoryEnum.TECH && <Tag  style={{height: '22px'}} color="geekblue">Технические отзывы</Tag>}
          
          {data.priority===SupportPriorityEnum.CRITICAL && <Tag  style={{height: '22px'}} color="red">Критический приоритет</Tag>}
          {data.priority===SupportPriorityEnum.HIGH && <Tag  style={{height: '22px'}} color="orange">Высокий приоритет</Tag>}
          {data.priority===SupportPriorityEnum.LOW && <Tag  style={{height: '22px'}}  color="blue">Низкий приоритет</Tag>}
          {data.priority===SupportPriorityEnum.NORMAL && <Tag  style={{height: '22px'}} color="green">Нормальный приоритет</Tag>}
          
  
        </div>
        Тема обращения: {data.subject}


      </div>

      {data.messages.map((item: SupportRequestMessage)=>{
        return <SupportMessage item={item} />
      })}
    </div>
    <Flex vertical>
      <Flex>Ответить</Flex>
      <textarea onChange={(e)=>{setAnswer(e.target.value)}}
        style={{width: '80%', height:'200px'}}
        value={answer}>
      </textarea>
      <Flex style={{width: '80%', height: '64px', justifyContent: 'flex-end', marginTop: '24px'}}>
        <Button onClick={sendSupportAnswer}>Отправить ответ</Button>

      </Flex>
    </Flex>
  </>
}
import React from "react";
import { transformGetCreateChatsOptionsQueryData, useGetCreateChatsOptionsQuery } from "../../store/webchatApiSlice";
import { Select } from "antd";

type PropsType = {
  value: any,
  type?: string,
  onChange: (value: any) => void
}

export const NeuronetsSelector: React.FC<PropsType> = ({ value, type, onChange })=>{

  const { data: createChatData } = useGetCreateChatsOptionsQuery({});

const data = transformGetCreateChatsOptionsQueryData(createChatData, type ? type : 'text')

  return <Select 
  defaultValue={null}
  value={value}
  style={{width: '300px'}} 
  onChange={onChange}>
  <Select.Option value={null}>Не выбрано</Select.Option>
  {data && data.map((item: any)=>{
    return <Select.Option 
      key={item.key} 
      value={item.key}>
        {item.name}
      </Select.Option>
  })}
  </Select>
}
import { Button, Popconfirm, Table, Tooltip } from "antd";
import React, { useState } from "react";
import { RootState, useAppDispatch } from "../../../store/store";
import { useGetNeuronetsQuery, useSaveNeuronetMutation } from "../../../store/neuronetApiSlice";

import { NeuronetEditor } from "./NeuronetEditor";
import { useSelector } from "react-redux";
import { DeleteOutlined, FormOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { SaveNeuronetType } from "../../../types/SaveNeuronet.type";
import { showNotification } from "../../../store/notificationSlice";
import { errorDecode } from "../../../api/errorDecode";

export const NeuronetsList: React.FC = ()=>{
  const dispatch = useAppDispatch()
  const [pagination, setPagination] = useState<any>({ total: 1, pageSize: 20, pageSizeOptions: [10, 20, 50, 100], current: 1 })
  const [editedItem, setEditedItem] = useState<any>()

  let currentAlgorithm = useSelector((state: RootState) => state.designMode.currentAlgorithm)

  const {data, isLoading, refetch } = useGetNeuronetsQuery({ 
    page: pagination.current,
    limit: pagination.pageSize
  })
  const [createNeuronet] = useSaveNeuronetMutation()
  const [openModal, setOpenModal] = useState<boolean>();

  
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Вывод",
      dataIndex: "output_type",
      key: "output_type",
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (text: string, record: any) => {
        return (
          <div className="operations">
            <span>
              <Tooltip title='Редактировать'>
                <FormOutlined onClick={handleOpenEditor(record)} />
              </Tooltip>
            </span>
            <span>
              <Popconfirm title="Вы уверены?" onConfirm={() => sureDelete(record)}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />} >
                <Tooltip title='Удалить пользователя'>
                  <DeleteOutlined color="#990000" />
                </Tooltip>
              </Popconfirm>
            </span>
          </div>
        );
      }
    },
  ];

  const sureDelete = (record: any)=>{
    refetch()
  }
  const handleOpenEditor = (record: any) => () => {
    setEditedItem(record)
    setOpenModal(true)

  }
  const changedTable = (e: any) => {
    setPagination(e)
  };
  const closeEditor = () => {
    setOpenModal(false)
    refetch()
  }
  const saveNeuronet = (editedItemLocal: SaveNeuronetType)=>{
    const {id, name, type, output_type, about_text, user_id, image, visibility, accounts} = editedItemLocal
    return createNeuronet({id, name, type, output_type, about_text, user_id, image, visibility, accounts})
    .unwrap()
    .then(() => {
      return true
    })
    .catch((error: any) => {
      const description = errorDecode(error)
      dispatch(showNotification({type: 'error',description, message: 'Ошибка сохранения'}))
      return false
    })
  }

  if(isLoading){
    return <>Loading...</>
  }
  if(!data) {
    return <>No data!</>
  }
  return <>
    {/* <Button onClick={()=>{handleOpenEditor(null)}}>add</Button> */}
    
    <div className="searchBar">
        <div>
          <span>
          </span>
          <span>
            <Button type="primary"
              icon={<PlusOutlined />}
              onClick={handleOpenEditor({name:"", type:"chatgpt", data:''})}>
              Добавить аккаунт нейросети 
            </Button>
          </span>
        </div>
      </div>
    <Table
        bordered
        columns={columns}
        dataSource={data[0]}
        pagination={pagination}
        loading={isLoading}
        onChange={changedTable}
        className={currentAlgorithm === 'dark' ? 'table_dark' : ""}
        rowKey={record => record.id}></Table>
    {openModal && <NeuronetEditor editedItem={editedItem} saveData={saveNeuronet} closeModal={closeEditor} modalVisible={openModal} />}
  </>
}
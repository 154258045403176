import { Button, DatePicker, Flex, Input, Popconfirm, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDeleteChatMutation, useGetAllChatsQuery } from "../../../store/webchatApiSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { IconCross } from "../../../Components/Icons/IconCross";
import "./AdminHistoryAudioGenerations.scss";
import { Loader } from "../../../Components/common/Loader";
import { QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { User } from "../../../types/User.type";
import { UserAvatar } from "../../../Components/common/UserAvatar";
import { IconLinearEye } from "../../../Components/Icons/IconLinearEye";
import { NeuronetsSelector } from "../../../Components/common/NeuronetsSelector";
import { ColumnsType } from "antd/es/table";
import UserSelector from "../../../Components/common/UserSelector";
import { Empty } from "../../../Components/common/Empty";
import { AdminHistoryAudioDialog } from "./AdminHistoryAudioDialog";
import { HistoryAudioDialog } from "../../lk/history/HistoryAudioDialog";

const { RangePicker } = DatePicker;

type PropsType = {
  showFull: boolean
}

export const AdminHistoryAudioGenerations: React.FC<PropsType> = ({showFull}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab = location.pathname
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const [ showItem, setShowItem] = useState<any>(null);
  
  const [neronet, setNeronet] = useState<any>();
  const [range, setRange] = useState<any>();
  const [q, setSearchQuery] = useState<string>();
  const [searchUser, setSearchUser] = useState<any>();
  const [favorites, setFavorites] = useState<boolean>(false);

  const [ deleteChatMutation ] = useDeleteChatMutation()
  const [pagination, setPagination] = useState<any>({
    total: 1,
    pageSize: 5,
    pageSizeOptions: [10, 20, 50, 100],
    current: 1,
  });
  const [tableSorter, setSorter] = useState<any>({
    field: "created_at",
    order: "descend",
  });
  const { data, isLoading } = useGetAllChatsQuery({
    page: pagination.current,
    limit: pagination.pageSize,
    output_type: activeTab==='/admin/history/audio/synthesis' ? "audio" : 'audio_recognition',
    order: tableSorter.field,
    order_dir: tableSorter.order,
    q,
    from: (range ? range[0]?.toDate().getTime() : undefined),
    to: (range ? range[1]?.toDate().getTime() : undefined),
    favorites,
    neuronet_id: (neronet ? neronet.split('_')[0] : undefined),
    option_id: (neronet && neronet.split('_').length===2)?neronet.split('_')[1]:undefined,
    user_id: (searchUser ? searchUser : undefined)
  });
  useEffect(() => {
    setPagination({ ...pagination, pageSize: showFull ? 10 : 5 });
  }, [showFull]);
  useEffect(() => {
    if (data) {
      setPagination({ ...pagination, total: data[1] })
    }
  }, [data])

  const columns: ColumnsType = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      sorter: true,
    },
    {
      title: "Пользователь",
      dataIndex: "user_id",
      key: "user_id",
      render: (text: string, record: any) => {
        const user: User = record.user;
        return <UserAvatar user={record.user} />
      },
    },
    {
      title: activeTab === '/admin/history/audio/synthesis' ? 'Запрос':'Генерация',
      dataIndex: "text",
      key: "text",
      render: (text: string, record: any) => {
        return <div className="historyAudioGenerationsTableText">
          {activeTab === '/admin/history/audio/synthesis' ? record.name : record.webchat_result_text}
        </div> 
      }
    },
    // {
    //   title: "Тип задачи",
    //   dataIndex: "neuronet",
    //   key: "neuronet",
    //   render: (text: string, record: any) => {
    //     if (record.option) {
    //       return (
    //         <div className="historyAudioGenerationsTableNeuronet">
    //           {record.option.image && (
    //             <img src={record.option.image.url} width="18px" alt="" />
    //           )}
    //           {record.option.name}
    //         </div>
    //       );
    //     } else if (record.neuronet) {
    //       return (
    //         <div className="historyAudioGenerationsTableNeuronet">
    //           {record.neuronet.image && (
    //             <img src={record.neuronet.image.url} width="18px" alt="" />
    //           )}
    //           {record.neuronet.name}
    //         </div>
    //       );
    //     }
    //   },
    // },
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      render: (text: string, record: any) => {
        const created_at = new Date(record.created_at).toLocaleDateString();
        return created_at;
      },
    },
    {
      title: "",
      dataIndex: "operation",
      render: (text: string, record: any) => {
        return (
          <div className="historyAudioGenerationsTableOperations">
            <span onClick={openChatDialog(record)}>
              <IconLinearEye />
            </span>
            <span>
              <Popconfirm style={{width: '200px'}}
                title="Удалить генерацию? Вы уверены?"
                placement="topRight"
                onConfirm={deleteChat(record)}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}>
                  <div>
                    <IconCross />
                  </div>
              </Popconfirm>
            </span>
          </div>
        );
      },
    },
  ];

  const changedTable = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    setPagination(pagination);
    if (sorter.field) {
      setSorter({ ...tableSorter, field: sorter.field, order: sorter.order });
    }
  };

  const deleteChat = (item: any) => () => {
    deleteChatMutation({chat_id: item.id})
  }
  const openChatDialog = (item: any) => () => {
    setShowItem(item)
  }
  const setDateRange = (e: any) => {
    setRange(e);
  };
  if(!data) return <Loader />

  return (
    <div className="adminHistoryAudioGenerations">
      {!showFull && <div className="historyAudioGenerationsHeader">
        <span>Генерация аудио</span>
        <span>
          <Button disabled={!isLoading && data && data[1]===0} onClick={()=>navigate('/admin/history/audio/synthesis')}>Смотреть все генерации</Button>
        </span>
      </div>
      }
      {showFull && (<>
        <Flex>
           <Tabs 
            defaultValue={''}
            activeKey={activeTab}
            onChange={navigate}
            items={[
              {
                key: '/admin/history/audio/synthesis',
                label: 'Синтез речи',
              },
              {
                key: '/admin/history/audio/recognition',
                label: 'Распознавание речи',
              },
            ]}> 
              <Tabs.TabPane>Синтез речи</Tabs.TabPane>
            </Tabs>
        </Flex>
        <div className={"historyAudioGenerationsFilters "+currentAlgorithm}>
          <div className="historyAudioGenerationsFiltersDatePicker">
            <span>Период</span>
            <span>
              <RangePicker value={range} onChange={setDateRange} />
            </span>
          </div>
          <div  className="historyAudioGenerationsFiltersUsers">
            <span>
              Пользователи
              </span>
              <span>
            <UserSelector value={searchUser} onChange={setSearchUser} />
              </span>
          </div>
          <div  className="historyAudioGenerationsFiltersQuery">
            <span>
              Поиск
              </span>
              <span>
            <Input.Search placeholder="Поиск"
              size="middle"
              allowClear={true}
              value={q}
              onChange={(e) => { setSearchQuery(e.target.value) }}
              enterButton={<Button type="primary"><SearchOutlined /></Button>}
            />
              </span>
          </div>
        </div>
      </>
      )}
      { !isLoading && data && data[1]>0 &&
        <Table
          bordered={false}
          columns={columns}
          dataSource={data[0]}
          loading={isLoading}
          onChange={changedTable}
          pagination={pagination} 
          className={
            "historyAudioGenerationsTable " +
            (currentAlgorithm === "dark" ? "table_dark" : "")
          }
          rowKey={(record) => record.id}
          ></Table>
      }
      { !isLoading && data && data[1]===0 &&
        <Empty />
      }

        { showItem && <HistoryAudioDialog item={showItem} open={!!showItem} close={()=>{setShowItem(null)}}/> }
    </div>
  );
};
